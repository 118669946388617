import styled from '@emotion/styled'
import { parseSize } from '@src/components/basic/utils'
import { colors, zIndices } from '@src/styles/theme'
import {
  BannerComponent as BannerComponentType,
  BannerItem,
  DisplayTypeEnum,
  ImageRatio,
} from '@src/types/channel-component'
import { getCustomPageRoute } from '@src/utils/get-custom-page-route'
import Link from 'next/link'
import { useState } from 'react'
import { Autoplay } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

type Props = {
  component: BannerComponentType
}

export const BannerComponent = ({ component }: Props) => {
  const { displayType, items, imageRatio } = component
  const [slideIndex, setSlideIndex] = useState<number>(0)

  return (
    <>
      {displayType === DisplayTypeEnum.Fixed &&
        items.map((item, index) => {
          return (
            <BannerComponentItem
              key={index}
              componentItem={item}
              imageRatio={imageRatio}
            />
          )
        })}

      {displayType === DisplayTypeEnum.Rolling && (
        <BannerWrapper>
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            onSlideChange={(swiper) => setSlideIndex(swiper.realIndex)}
          >
            {items.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <BannerComponentItem
                    componentItem={item}
                    imageRatio={imageRatio}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <BannerIndexIndicator
            currentIndex={slideIndex + 1}
            maxIndex={items.length}
          />
        </BannerWrapper>
      )}
    </>
  )
}

const BannerComponentItem = ({
  componentItem,
  imageRatio,
}: {
  componentItem: BannerItem
  imageRatio: ImageRatio
}) => {
  return (
    <Link href={getCustomPageRoute(componentItem.pagePath)}>
      <BannerImage
        src={componentItem.image || ''}
        imageRatio={imageRatio}
        alt=""
      />
    </Link>
  )
}

const BannerImage = styled.img<{
  imageRatio: ImageRatio
}>`
  width: 100%;
  object-fit: cover;
  aspect-ratio: ${(props) => props.imageRatio[0] / props.imageRatio[1]};
`

const BannerWrapper = styled.div`
  position: relative;
`

const BannerIndexIndicator = styled.span<{
  currentIndex: number
  maxIndex: number
}>`
  padding: ${parseSize(3)} ${parseSize(7)};
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 0.6875rem;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  letter-spacing: -0.04em;
  color: ${colors.white};

  position: absolute;
  bottom: 1.25rem;
  right: 1.5rem;

  z-index: ${zIndices.above};

  ::before {
    content: '${(props) => props.currentIndex}';
    font-weight: 800;
  }

  ::after {
    content: '${(props) => ` / ${props.maxIndex}`}';
  }
`
