import { useMemo, useState } from 'react'

type Params<T = unknown> = {
  data: T[]
  itemsPerPage: number
  initialPage?: number
}

export const usePaginatedData = <T>({
  data,
  itemsPerPage,
  initialPage = 1,
}: Params<T>) => {
  const [page, setPage] = useState<number>(initialPage)
  const lastPage = Math.ceil(data.length / itemsPerPage)

  const paginatedData = useMemo(() => {
    return data.slice((page - 1) * itemsPerPage, page * itemsPerPage)
  }, [data, itemsPerPage, page])

  return { page, setPage, lastPage, paginatedData }
}
