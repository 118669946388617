import styled from '@emotion/styled'
import { ImageRatio } from '@src/types/channel-component'
import { getCustomPageRoute } from '@src/utils/get-custom-page-route'
import Link from 'next/link'

type Props = {
  pagePath?: string
  src?: string
  imageRatio?: ImageRatio
}

export const ComponentImage = ({ pagePath, src, imageRatio }: Props) => {
  return (
    <Link href={getCustomPageRoute(pagePath) || ''}>
      <Image
        src={src}
        imageRatio={imageRatio || [1, 1]}
        alt="component-image"
      />
    </Link>
  )
}

const Image = styled.img<{ imageRatio: ImageRatio }>`
  width: 100%;
  aspect-ratio: ${({ imageRatio }) => imageRatio[0] / imageRatio[1]};
  object-fit: cover;
`

export default ComponentImage
