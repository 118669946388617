import { useComponentProductsInfiniteQuery } from '@api/hooks/product/use-component-products'
import { Box, VStack } from '@src/components/basic'
import { LoadingSpinner, Pagination } from '@src/components/common'
import {
  DisplayTypeEnum,
  ImageListComponent as ImageListComponentType,
  ImageListItem,
} from '@src/types/channel-component'
import { useState } from 'react'
import { ComponentImage } from '../../common/component-image'
import { FixedHorizontalProductList } from '../../product-list/fixed-horizontal'
import { SwipeProductList } from '../../product-list/swipe'
import { ComponentTitle } from './_common/component-title'

type Props = {
  component: ImageListComponentType
}

export const ImageListComponent = ({ component }: Props) => {
  const { title, subTitle, items, displayType, imageRatio } = component
  const [page, setPage] = useState<number>(1)
  const lastPage = items.length
  const currentItem = items[page - 1]

  return (
    <VStack spacing={10} paddingY={24}>
      <ComponentTitle title={title} subTitle={subTitle} />
      {displayType === DisplayTypeEnum.Scroll && (
        <VStack spacing={24}>
          {items.map((item, index) => (
            <ImageListComponentItem
              key={index}
              componentItem={item}
              displayType={displayType}
              imageRatio={imageRatio}
            />
          ))}
        </VStack>
      )}
      {displayType === DisplayTypeEnum.Button && (
        <VStack>
          <ImageListComponentItem
            componentItem={currentItem}
            displayType={displayType}
            imageRatio={imageRatio}
          />
          <Box centered marginTop={32}>
            <Pagination
              page={page}
              onChangePage={setPage}
              lastPage={lastPage}
            />
          </Box>
        </VStack>
      )}
    </VStack>
  )
}

const ImageListComponentItem = ({
  componentItem,
  displayType,
  imageRatio,
}: {
  componentItem: ImageListItem
  displayType: ImageListComponentType['displayType']
  imageRatio: ImageListComponentType['imageRatio']
}) => {
  const { mainDisplayProductList } = componentItem
  const componentProductsInfiniteQuery = useComponentProductsInfiniteQuery({
    productIds: mainDisplayProductList,
  })

  if (componentProductsInfiniteQuery.isLoading) {
    return <LoadingSpinner />
  }

  return (
    <VStack spacing={8}>
      <ComponentImage
        src={componentItem.image}
        imageRatio={imageRatio}
        pagePath={componentItem.pagePath}
      />
      {displayType === DisplayTypeEnum.Scroll && (
        <SwipeProductList productList={componentProductsInfiniteQuery.data} />
      )}
      {displayType === DisplayTypeEnum.Button && (
        <FixedHorizontalProductList
          productList={componentProductsInfiniteQuery.data}
        />
      )}
    </VStack>
  )
}
