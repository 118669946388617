import { useComponentProductsInfiniteQuery } from '@api/hooks/product/use-component-products'
import { Box, VStack } from '@src/components/basic'
import { Pagination } from '@src/components/common'
import { usePaginatedData } from '@src/hook/use-paginated-data'
import {
  DisplayTypeEnum,
  RankListComponent as RankListComponentType,
} from '@src/types/channel-component'
import { EmptyProductPlaceholder } from '../../common/empty-product-placeholder'
import { RankProductList } from '../../product-list/rank'
import { RankSwipeProductList } from '../../product-list/rank-swipe'
import { ComponentTitle } from './_common/component-title'

const itemsPerPage = 5

type RankListComponentProps = {
  component: RankListComponentType
}

export const RankListComponent = ({ component }: RankListComponentProps) => {
  const { title, items, displayType } = component
  const componentItem = items[0]
  const componentProductsInfiniteQuery = useComponentProductsInfiniteQuery({
    productIds: componentItem?.mainDisplayProductList,
  })

  const {
    page,
    setPage,
    paginatedData: paginatedProducts,
    lastPage,
  } = usePaginatedData({
    data: componentProductsInfiniteQuery.data || [],
    itemsPerPage,
  })

  if (componentProductsInfiniteQuery.total === 0) {
    return (
      <VStack spacing={16} paddingY={24}>
        <ComponentTitle title={title} />
        <EmptyProductPlaceholder variant="box" />
      </VStack>
    )
  }

  if (displayType === DisplayTypeEnum.Swipe) {
    return (
      <VStack spacing={16} paddingY={24}>
        <ComponentTitle title={title} />
        <RankSwipeProductList products={componentProductsInfiniteQuery.data} />
      </VStack>
    )
  }

  return (
    <VStack spacing={8} paddingY={24}>
      <ComponentTitle title={title} />
      <Box
        paddingX={16}
        paddingY={8}
        boxSizing="content-box"
        minHeight={displayType === DisplayTypeEnum.Accordion ? 308 : 196}
      >
        <RankProductList
          products={paginatedProducts}
          startRank={(page - 1) * itemsPerPage + 1}
          isAccordion={displayType === DisplayTypeEnum.Accordion}
        />
      </Box>
      <Box paddingBottom={16}>
        <Pagination page={page} lastPage={lastPage} onChangePage={setPage} />
      </Box>
    </VStack>
  )
}
