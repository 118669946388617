import { Box, HStack, Text, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { ExpandButton, ExpandableBox } from '@src/components/common'
import { Clickable } from '@src/components/common/clickable'
import { useProductDetailDrawer } from '@src/contexts/product-detail-drawer-context'
import { BaseProduct } from '@src/types/product'
import { FixedHorizontalProductItem } from '../fixed-horizontal/fixed-horizontal-product-item'

type RankProductItemProps = {
  product: BaseProduct
  rank: number
  isAccordion?: boolean
  isExpanded?: boolean
  onClickExpandButton?: () => void
}

export const RankProductItem = ({
  product,
  rank,
  isAccordion,
  isExpanded,
  onClickExpandButton,
}: RankProductItemProps) => {
  const { openProductDetail } = useProductDetailDrawer()

  const handleClick = () => {
    if (isAccordion) {
      onClickExpandButton && onClickExpandButton()
      return
    }

    openProductDetail(product.id)
  }

  return (
    <VStack>
      <Clickable onClick={handleClick}>
        <HStack spacing={2}>
          <Box width={24} centered>
            <Text
              fontColor="primary"
              fontWeight="bold"
              fontSize={18}
              lineHeight={parseSize(20)}
            >
              {rank}.
            </Text>
          </Box>
          <Text
            fontSize={17}
            lineHeight={parseSize(20)}
            lineClamp={1}
            fontColor="gray.800"
            width="100%"
            textAlign="left"
          >
            {product.title}
          </Text>
          {isAccordion && (
            <ExpandButton expanded={!!isExpanded} size={16} color="gray.600" />
          )}
        </HStack>
      </Clickable>
      {isAccordion && (
        <ExpandableBox expanded={isExpanded}>
          <Box paddingTop={12}>
            <FixedHorizontalProductItem product={product} />
          </Box>
        </ExpandableBox>
      )}
    </VStack>
  )
}
