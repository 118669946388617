import styled from '@emotion/styled'
import { BaseProduct } from '@src/types/product'
import { EmptyProductPlaceholder } from '../../common/empty-product-placeholder'
import { FixedVerticalProductItem } from './fixed-vertical-product-item'

type Props = {
  productList?: BaseProduct[]
  cols: number
  disabled?: boolean
}

export const FixedVerticalProductList = ({
  productList,
  cols,
  disabled,
}: Props) => {
  if (productList?.length === 0) {
    return <EmptyProductPlaceholder />
  }

  return (
    <Container cols={cols}>
      {productList?.map((product: BaseProduct, index) => (
        <FixedVerticalProductItem
          key={index}
          product={product}
          disabled={disabled}
        />
      ))}
    </Container>
  )
}

const Container = styled.div<{ cols: Props['cols'] }>`
  display: grid;
  grid-template-columns: ${({ cols }) => Array(cols).fill('1fr').join(' ')};
  gap: ${({ cols }) => (cols === 1 ? '1rem' : '0.5rem')};
  padding: 0 1rem;
`
