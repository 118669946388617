import { useCustomerServiceInfoQuery } from '@api/hooks/channel/use-customer-service-info'
import { HStack, Text, VStack } from '@src/components/basic'

const OperatingHours = () => {
  const customerServiceInfoQuery = useCustomerServiceInfoQuery()

  return (
    <VStack alignItems={'center'} spacing={4}>
      <HStack spacing={8}>
        <Text fontColor={'purple.dark.200'} fontSize={12}>
          Uzzim 고객센터
        </Text>
        <Text fontColor={'purple.dark.200'} fontSize={15} fontWeight={'bold'}>
          {customerServiceInfoQuery.data?.contactNumber}
        </Text>
      </HStack>
      <Text fontColor={'purple.dark.200'} fontSize={12}>
        평일 오전 10시 30분 - 오후 5시 30분 운영
      </Text>
    </VStack>
  )
}

export default OperatingHours
