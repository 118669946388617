import { Box, Text } from '@src/components/basic'

type RankingChipProps = {
  ranking: number
}

export const RankingChip = ({
  ranking,
  ...positionProps
}: RankingChipProps) => {
  return (
    <Box size={40} backgroundColor="primary" centered>
      <Text fontColor="white" fontSize={17} fontWeight="bold">
        #{ranking}
      </Text>
    </Box>
  )
}
