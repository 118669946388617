import { Box, VStack } from '@src/components/basic'
import { Copyright } from '@src/components/home/footer/copyright'
import { FooterDescriptions } from '@src/components/home/footer/footer-descriptions'
import OperatingHours from '@src/components/home/footer/operating-hours'
import { OperationBar } from '@src/components/home/footer/operation-bar'

export const Footer = () => {
  return (
    <Box backgroundColor={'gray.100'}>
      <VStack
        paddingTop={32}
        paddingBottom={24}
        spacing={40}
        paddingX={16}
        alignItems={'center'}
      >
        <VStack spacing={16} width={'100%'}>
          <OperatingHours />
          <FooterDescriptions />
        </VStack>
        <OperationBar />
        <Copyright />
      </VStack>
    </Box>
  )
}
