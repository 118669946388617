import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { ApiListResult2 } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { useCustomInfiniteQueryResult } from '@src/hook/use-custom-infinite-query-result'
import {
  BaseProduct,
  GetComponentProductsRequestDto,
  ProductDto,
} from '@src/types/product'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getNextPageParam } from '../_utils/get-next-page-param'
import { selectInfiniteData } from '../_utils/select-infinite-data'

export const useComponentProductsInfiniteQuery = (
  dto: GetComponentProductsRequestDto
) => {
  const query = useInfiniteQuery(
    [queryKeys.product.componentProducts, dto],
    ({ pageParam = 1 }) =>
      getComponentProducts({
        ...dto,
        page: pageParam,
      }),
    {
      enabled: !!dto.productIds,
      getNextPageParam: (lastPage) => getNextPageParam(lastPage),
      staleTime: staleTime.long,
      select: (data) => selectInfiniteData(data, (dto) => new BaseProduct(dto)),
    }
  )

  return useCustomInfiniteQueryResult(query)
}

const getComponentProducts = (dto: GetComponentProductsRequestDto) => {
  return api.productClient.post<ApiListResult2<ProductDto>>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/component-products`,
    dto
  )
}
