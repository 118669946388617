import { useComponentProductsInfiniteQuery } from '@api/hooks/product/use-component-products'
import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { Icon, LoadingSpinner, TabBar } from '@src/components/common'
import { DisplayTypeEnum } from '@src/types/channel-component'
import {
  SubMenuComponent as SubMenuComponentType,
  SubMenuItem,
} from '@src/types/channel-component/entities/component-types/sub-menu'
import { Selectable } from '@src/types/util-types/selectable'
import { getCustomPageRoute } from '@src/utils/get-custom-page-route'
import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
import { FixedVerticalProductList } from '../../product-list/fixed-vertical'
import { ComponentTitle } from './_common/component-title'

type Props = {
  component: SubMenuComponentType
}

export const SubMenuComponent = ({ component }: Props) => {
  const { displayType, title, items, productCols } = component
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  useEffect(() => {
    setSelectedIndex(items[0]?.index || 0)
  }, [items])

  const selectedItem = useMemo(
    () => items.find((item) => item.index === selectedIndex),
    [items, selectedIndex]
  )

  const componentProductsInfiniteQuery = useComponentProductsInfiniteQuery({
    productIds: selectedItem?.mainDisplayProductList || [],
  })

  const isCategory = !!selectedItem?.categoryId

  return (
    <VStack paddingY={24}>
      <ComponentTitle title={title} />
      <VStack spacing={16}>
        {displayType === DisplayTypeEnum.Swipe && (
          <SwipeSubMenu
            list={items || []}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        )}

        {displayType === DisplayTypeEnum.Fixed && (
          <FixedSubMenu
            list={items || []}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        )}

        {componentProductsInfiniteQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <FixedVerticalProductList
            productList={componentProductsInfiniteQuery.data}
            cols={productCols}
          />
        )}
      </VStack>
      {selectedItem?.pagePath && (
        <Link href={getCustomPageRoute(selectedItem.pagePath)}>
          <MoreButton label={'상품 더보기'} />
        </Link>
      )}
      {isCategory && (
        <Link
          href={{
            pathname: routes.shopping.categoryResult,
            query: { large: selectedItem.categoryId },
          }}
        >
          <MoreButton label={'카테고리 더보기'} />
        </Link>
      )}
    </VStack>
  )
}

type SubMenuProps = {
  list: SubMenuItem[]
  selectedIndex: number
  setSelectedIndex: (index: number) => void
}

const SwipeSubMenu = ({
  list,
  selectedIndex,
  setSelectedIndex,
}: SubMenuProps) => {
  const tabs = useMemo<Selectable<number>[]>(
    () =>
      list.map(({ name, categoryName, index }) => ({
        label: categoryName || name || '',
        value: index || 0,
      })),
    [list]
  )

  return (
    <TabBar
      tabs={tabs}
      selectedTab={selectedIndex}
      onChange={setSelectedIndex}
    />
  )
}

const FixedSubMenu = ({
  list,
  selectedIndex,
  setSelectedIndex,
}: SubMenuProps) => {
  return (
    <Box
      maxWidth="100%"
      flexWrap="wrap"
      flexDirection="row"
      paddingY={1}
      marginX={-1}
      overflowX="hidden"
    >
      {list.map(({ index, name, categoryName }) => (
        <Box
          key={index}
          as="button"
          onClick={() => typeof index === 'number' && setSelectedIndex(index)}
          border={[2, 'solid', 'gray.100']}
          backgroundColor={
            index === selectedIndex ? 'purple.light.400' : 'white'
          }
          centered
          height={48}
          width={`calc(${(1 / 3) * 100}% + ${parseSize(2)})`}
          margin={-1}
        >
          <Text
            fontColor={index === selectedIndex ? 'primary' : 'gray.500'}
            fontSize={13}
          >
            {categoryName || name}
          </Text>
        </Box>
      ))}
    </Box>
  )
}

type MoreButtonProps = {
  label: string
}

const MoreButton = ({ label }: MoreButtonProps) => {
  return (
    <Box paddingX={16}>
      <Box border={[1, 'solid', 'gray.100']} height={40} centered>
        <HStack spacing={2}>
          <Text fontSize={12} fontColor="gray.700">
            {label}
          </Text>
          <Icon iconName="next" color="gray.700" size={12} />
        </HStack>
      </Box>
    </Box>
  )
}
