import React from 'react'
import { HStack, Text } from '@src/components/basic'
import { routes } from '@src/common/constants/routes'
import Link from 'next/link'
import { VDivider } from '@src/components/common'

export const OperationBar = () => {
  return (
    <HStack spacing={8}>
      {operationItemList.map(({ label, link }, index) => (
        <HStack key={link} alignItems={'center'} spacing={8}>
          <Link href={link}>
            <Text fontSize={10} fontWeight={'semibold'}>
              {label}
            </Text>
          </Link>
          {index !== operationItemList.length - 1 && (
            <VDivider thickness={1} length={16} />
          )}
        </HStack>
      ))}
    </HStack>
  )
}

type OperationItemProps = {
  label: string
  link: string
}

const operationItemList: OperationItemProps[] = [
  {
    label: '공지사항',
    link: routes.info.notice,
  },
  {
    label: '개인정보처리방침',
    link: routes.info.privacyPolicy,
  },
  {
    label: '이용약관',
    link: routes.info.termsOfService,
  },
  {
    label: 'Uzzim 머니 약관',
    link: routes.info.uzzimMoney,
  },
  {
    label: 'FAQ',
    link: routes.info.faq,
  },
  // {
  //   label: '1:1문의',
  //   link: routes.info.inquiry,
  // },
]
