import { VStack } from '@src/components/basic'
import { BaseProduct } from '@src/types/product'
import { EmptyProductPlaceholder } from '../../common/empty-product-placeholder'
import { FixedHorizontalProductItem } from './fixed-horizontal-product-item'

type Props = {
  productList?: BaseProduct[]
}

export const FixedHorizontalProductList = ({ productList }: Props) => {
  if (productList?.length === 0) {
    return <EmptyProductPlaceholder />
  }

  return (
    <VStack paddingX={16} spacing={8}>
      {productList?.map((product, index) => (
        <FixedHorizontalProductItem key={index} product={product} />
      ))}
    </VStack>
  )
}

export default FixedHorizontalProductList
