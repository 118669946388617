import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { QueryOptions } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import {
  ChannelComponentRequestDto,
  ChannelComponentResponseDto,
} from '@src/types/channel-component'
import { useQuery } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'

type Options = QueryOptions<ChannelComponentResponseDto[]>

const defaultOptions: Options = {
  enabled: true,
}

export const useChannelComponentsQuery = (
  isShoppingHome = true,
  options = defaultOptions
) => {
  return useQuery(
    [queryKeys.shopping.channelComponents, isShoppingHome],
    () =>
      getChannelComponents({
        isActive: isShoppingHome,
      }),
    {
      enabled: options.enabled,
      select: (data) => {
        if (isShoppingHome) {
          return data
        }

        return data.length > 3 ? data.slice(data.length - 3) : data
      },
      staleTime: staleTime.long,
    }
  )
}

const getChannelComponents = (dto: ChannelComponentRequestDto) => {
  return api.channelClient.get<ChannelComponentResponseDto[]>(
    `${environment.harmonyEndpoint}/${BasicSettings.getChannelId()}/components`,
    { params: snakecaseKeys(dto) }
  )
}
