import { Box, HStack, VStack } from '@src/components/basic'
import { CustomImage } from '@src/components/common'
import { Clickable } from '@src/components/common/clickable'
import { useProductDetailDrawer } from '@src/contexts/product-detail-drawer-context'
import { BaseProduct, InventoryStatusEnum } from '@src/types/product'
import { DisableOverlay } from '../../common/disabled-overlay'
import { Price } from '../../common/price'
import { ProductTitle } from '../../common/product-title'
import { RankingChip } from '../../common/ranking-chip'
import { SoldOutOverlay } from '../../common/sold-out-overlay'
import { TextBadge } from '../../common/text-badge'
import { TimeSaleChip } from '../../common/time-sale-chip'
import { WishlistButton } from '../../common/wishlist-button'

type RankSwipeProductItemProps = {
  product: BaseProduct
  disabled?: boolean
  rank: number
}

export const RankSwipeProductItem = ({
  product,
  disabled,
  rank,
}: RankSwipeProductItemProps) => {
  const { openProductDetail } = useProductDetailDrawer()
  const {
    images,
    id,
    wishlistId,
    title,
    brand,
    marketPrice,
    minSellPrice,
    timesaleAppliedNow,
    inventoryStatus,
  } = product

  return (
    <Clickable
      onClick={() => !disabled && openProductDetail(id)}
      disabled={disabled}
    >
      <VStack spacing={6}>
        <Box position="relative" border={[1, 'solid', 'gray.100']}>
          <CustomImage
            src={images[0] || require('@src/assets/shopping/empty_130.png')}
            alt={title}
            layout="responsive"
            width={1}
            height={1}
          />
          <Box position="absolute" top={8} right={8}>
            <HStack>
              <RankingChip ranking={rank} />
              <WishlistButton
                productId={id}
                wishItemId={wishlistId}
                disabled={disabled}
              />
            </HStack>
          </Box>
          {timesaleAppliedNow && <TimeSaleChip top={8} left={8} />}
          {inventoryStatus === InventoryStatusEnum.SoldOut && (
            <SoldOutOverlay />
          )}
          {disabled && <DisableOverlay />}
        </Box>

        <VStack minHeight={92} spacing={6} width="100%">
          <ProductTitle title={title} brand={brand} titleLineCount={2} />
          <Price marketPrice={marketPrice} sellPrice={minSellPrice} />
          <TextBadge direction="column" />
        </VStack>
      </VStack>
    </Clickable>
  )
}
