import React from 'react'
import { HStack, Text, VStack } from '@src/components/basic'

export const FooterDescriptions = () => {
  return (
    <VStack spacing={4}>
      <HStack spacing={6}>
        <Text fontSize={10} fontWeight={'semilight'}>
          가비파트너스 주식회사
        </Text>
        <Text fontSize={10} fontWeight={'semilight'}>
          대표이사 송호경
        </Text>
      </HStack>
      {descriptionList.map(({ label, description }) => (
        <DescriptionItem key={label} label={label} description={description} />
      ))}
    </VStack>
  )
}

type DescriptionItemProps = {
  label: string
  description: string
}

const DescriptionItem = ({ label, description }: DescriptionItemProps) => {
  return (
    <HStack spacing={10}>
      <Text fontSize={10} color={'gray.800'} fontWeight={'semilight'}>
        {label}
      </Text>
      <Text fontSize={10} fontWeight={'semilight'}>
        {description}
      </Text>
    </HStack>
  )
}

const descriptionList: DescriptionItemProps[] = [
  {
    label: '개인정보보호책임자',
    description: '조경구',
  },
  {
    label: '주소',
    description: '서울특별시 서초구 서초중앙로22길 62, 2층(서초동, 유성빌딩)',
  },
  {
    label: '사업자등록번호',
    description: '429-88-01381',
  },
  {
    label: '통신판매사업신고',
    description: '제 2022-서울서초-3298호',
  },
  {
    label: '이메일',
    description: 'uzzim@gabipartners.co.kr',
  },
  {
    label: '호스팅사업자',
    description: 'AWS 코리아',
  },
]
