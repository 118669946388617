import { VStack } from '@src/components/basic'
import { BaseProduct } from '@src/types/product'
import { useEffect, useState } from 'react'
import { RankProductItem } from './rank-product-item'

type RankProductListProps = {
  products?: BaseProduct[]
  startRank?: number
  isAccordion?: boolean
}

export const RankProductList = ({
  products = [],
  startRank = 1,
  isAccordion,
}: RankProductListProps) => {
  const getRank = (index: number) => index + startRank
  const [expandedProductId, setExpandedProductId] = useState<string | null>(
    products[0]?.id
  )

  useEffect(() => {
    if (!products[0]) {
      return
    }

    setExpandedProductId(products[0].id)
  }, [products])

  return (
    <VStack spacing={24}>
      {products.map((product, index) => (
        <RankProductItem
          key={getRank(index)}
          product={product}
          rank={getRank(index)}
          isAccordion={isAccordion}
          isExpanded={product.id === expandedProductId}
          onClickExpandButton={() =>
            product.id === expandedProductId
              ? setExpandedProductId(null)
              : setExpandedProductId(product.id)
          }
        />
      ))}
    </VStack>
  )
}
