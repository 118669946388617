import { Box, HStack } from '@src/components/basic'
import { useCountdown } from '@src/hook/use-countdown'
import { BaseProduct } from '@src/types/product'
import { useMemo } from 'react'
import { TimeSaleProductItem } from './times-sale-product-item'

type Props = {
  productList?: BaseProduct[]
  startDt: string
  endDt: string
}

export const TimeSaleProductList = ({ productList, startDt, endDt }: Props) => {
  const now = new Date()
  const startDate = new Date(startDt)
  const endDate = useMemo(() => new Date(endDt), [endDt])
  const { time } = useCountdown(endDate)
  const isDisabled = now < startDate || now > endDate

  return (
    <Box width="100%" overflowX="auto" display="box" paddingX={16}>
      {productList?.length === 1 && (
        <Box width="100%">
          <TimeSaleProductItem
            product={productList[0]}
            startDate={startDate}
            time={time}
            disabled={isDisabled}
          />
        </Box>
      )}
      {(productList?.length || 0) > 1 && (
        <HStack spacing={16} display="inline-flex">
          {productList?.map((product) => (
            <TimeSaleProductItem
              key={product.id}
              product={product}
              startDate={startDate}
              time={time}
              disabled={isDisabled}
            />
          ))}
        </HStack>
      )}
    </Box>
  )
}
