import styled from '@emotion/styled'
import { Text } from '../basic'
import { TextComponentProps } from './text'

export const SectionTitle = ({
  children,
  ...textProps
}: TextComponentProps) => {
  return (
    <Text
      as="h2"
      fontWeight="bold"
      fontSize={16}
      fontColor="black"
      lineHeight={1.2}
      {...textProps}
    >
      {children}
    </Text>
  )
}

export const SectionSubTitle = ({
  children,
  ...textProps
}: TextComponentProps) => {
  return (
    <Text
      as="h3"
      fontWeight="light"
      fontSize={14}
      fontColor="gray.600"
      lineHeight={1}
      {...textProps}
    >
      {children}
    </Text>
  )
}
