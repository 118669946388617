import { Box, HStack, Text, VStack } from '@src/components/basic'
import { HDivider } from '@src/components/common'
import { TimeRemaining } from '@src/hook/use-countdown'

type CountdownDisplayProps = {
  startDate: Date
  time: TimeRemaining
}

export const CountdownDisplay = ({
  startDate,
  time,
}: CountdownDisplayProps) => {
  const [day, hour, min, sec] = time

  const isNotStarted = new Date().getTime() < startDate.getTime()
  const isEnded = time.every((timeUnit) => timeUnit === null)

  return (
    <VStack alignItems="center" width="100%">
      {isNotStarted ? (
        <Box centered height={38}>
          <Text fontSize={16} fontWeight="bold" fontColor="purple.dark.100">
            곧 세일이 시작됩니다.
          </Text>
        </Box>
      ) : isEnded ? (
        <Box centered height={38}>
          <Text fontSize={16} fontWeight="bold" fontColor="purple.dark.100">
            세일이 종료되었습니다.
          </Text>
        </Box>
      ) : (
        <HStack spacing={6} height={38}>
          <TimeUnit value={day} unit={'일'} />
          <TimeUnit value={hour} unit={'시간'} />
          <TimeUnit value={min} unit={'분'} />
          <TimeUnit value={sec} unit={'초'} />
          <Text fontColor="purple.dark.100" fontSize={11}>
            남았어요!
          </Text>
        </HStack>
      )}
      <HDivider backgroundColor="purple.dark.100" thickness={1} />
    </VStack>
  )
}

type TimeUnitProps = {
  value: number | null
  unit: string
}

const TimeUnit = ({ value, unit }: TimeUnitProps) => {
  if (value === null) {
    return null
  }

  return (
    <HStack spacing={2}>
      <Text fontColor="purple.dark.100" fontSize={20} fontWeight="bold">
        {value}
      </Text>
      <Text fontColor="purple.dark.100" fontSize={11}>
        {unit}
      </Text>
    </HStack>
  )
}
