import { routes } from '@src/common/constants/routes'
import { Box, Text, VStack } from '@src/components/basic'
import { blurImageUrl } from '@src/components/common/card'
import {
  IconMenuComponent as IconMenuComponentType,
  ItemTypeEnum,
} from '@src/types/channel-component'
import { getCustomPageRoute } from '@src/utils/get-custom-page-route'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { UrlObject } from 'url'

type Props = {
  component: IconMenuComponentType
}

export const IconMenuComponent = ({ component }: Props) => {
  const { items, itemType } = component

  return (
    <Box padding={16}>
      <Box
        display="grid"
        gridTemplateColumns={'repeat(3, 1fr)'}
        paddingX={16}
        paddingY={20}
        border={[1, 'solid', 'gray.100']}
        borderRadius={16}
        gap={16}
      >
        {items.map(
          ({ image, name, categoryName, categoryId, pagePath }, index) => (
            <IconMeneButton
              key={index}
              href={
                itemType === ItemTypeEnum.Category
                  ? {
                      pathname: routes.shopping.categoryResult,
                      query: { large: categoryId },
                    }
                  : getCustomPageRoute(pagePath)
              }
              imageUrl={image}
              label={categoryName || name || ''}
              isDefaultIcon={`${image}`.includes('icons/default')}
            />
          )
        )}
      </Box>
    </Box>
  )
}

type IconMenuButtonProps = {
  imageUrl: string
  href: UrlObject | string
  label: string
  isDefaultIcon: boolean
}

const IconMeneButton = ({
  imageUrl,
  href,
  label,
  isDefaultIcon,
}: IconMenuButtonProps) => {
  return (
    <Link href={href}>
      <VStack spacing={6} alignItems="center">
        <Box
          size={48}
          backgroundColor="gray.100"
          position="relative"
          borderRadius="full"
          overflow="hidden"
          centered
        >
          <Image
            src={imageUrl || blurImageUrl}
            {...(isDefaultIcon
              ? { layout: 'fixed', width: 24, height: 24 }
              : { layout: 'fill' })}
            alt="icon"
            unoptimized
          />
        </Box>
        <Text
          fontSize={13}
          fontWeight="regular"
          lineClamp={1}
          maxWidth={100}
          wordBreak="break-all"
        >
          {label}
        </Text>
      </VStack>
    </Link>
  )
}
