import {
  ChannelComponentResponseDto,
  ComponentTypeEnum,
} from '@src/types/channel-component'
import { ChannelComponent } from './channel-component'

type ChannelComponentListProps = {
  components: ChannelComponentResponseDto[]
}

export const ChannelComponentList = ({
  components,
}: ChannelComponentListProps) => {
  return (
    <>
      {components.map((component, index) => (
        <ChannelComponent
          key={index}
          component={component}
          hasDivider={
            !(
              index === components.length - 1 ||
              component.componentType === ComponentTypeEnum.IconMenu ||
              component.componentType === ComponentTypeEnum.Banner
            )
          }
        />
      ))}
    </>
  )
}
