import { useEffect, useRef, useState } from 'react'

const millisecPerSec = 1000
const millisecPerMin = millisecPerSec * 60
const millisecPerHour = millisecPerMin * 60
const millisecPerDay = millisecPerHour * 24

export type TimeRemaining = [
  number | null,
  number | null,
  number | null,
  number | null
]
type CountDown = {
  time: TimeRemaining
  end: boolean
}

const useCountdown = (targetDate?: Date): CountDown => {
  const countdownDate = targetDate?.getTime() || 0
  const [countdown, setCountdown] = useState<number>(
    countdownDate - new Date().getTime()
  )
  const countdownEndedRef = useRef<boolean>(false)

  useEffect(() => {
    if (countdownEndedRef.current) {
      return
    }

    const interval = setInterval(() => {
      setCountdown(countdownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countdown, countdownDate])

  useEffect(() => {
    if (countdown < 0) {
      countdownEndedRef.current = true
    }
  }, [countdown])

  return {
    time: getReturnValues(countdown),
    end: countdown <= 0,
  }
}

const getReturnValues = (countdown: number): TimeRemaining => {
  const days =
    countdown > millisecPerDay ? Math.floor(countdown / millisecPerDay) : null
  const hours =
    countdown > millisecPerHour
      ? Math.floor((countdown % millisecPerDay) / millisecPerHour)
      : null
  const minutes =
    countdown > millisecPerMin
      ? Math.floor((countdown % millisecPerHour) / millisecPerMin)
      : null
  const seconds =
    countdown > millisecPerSec
      ? Math.floor((countdown % millisecPerMin) / millisecPerSec)
      : null

  return [days, hours, minutes, seconds]
}

export { useCountdown }
