import styled from '@emotion/styled'
import { Box, HStack, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { CustomImage } from '@src/components/common'
import { Clickable } from '@src/components/common/clickable'
import { useProductDetailDrawer } from '@src/contexts/product-detail-drawer-context'
import { colors } from '@src/styles/theme'
import { BaseProduct, InventoryStatusEnum } from '@src/types/product'
import { Price } from '../../common/price'
import { ProductTitle } from '../../common/product-title'
import { SoldOutOverlay } from '../../common/sold-out-overlay'
import { TextBadge } from '../../common/text-badge'
import { TimeSaleChip } from '../../common/time-sale-chip'
import { WishlistButton } from '../../common/wishlist-button'

type Props = {
  product: BaseProduct
}

export const FixedHorizontalProductItem = ({ product }: Props) => {
  const { openProductDetail } = useProductDetailDrawer()
  const {
    id,
    images,
    wishlistId,
    title,
    brand,
    marketPrice,
    minSellPrice,
    timesaleAppliedNow,
    inventoryStatus,
  } = product

  return (
    <Clickable
      onClick={() => openProductDetail(id)}
      position="relative"
      flex={1}
    >
      <HStack alignItems="flex-start">
        <ImageWrapper>
          <CustomImage
            src={images[0] || require('@src/assets/shopping/empty_130.png')}
            alt={title}
            layout="fill"
          />
          {inventoryStatus === InventoryStatusEnum.SoldOut && (
            <SoldOutOverlay />
          )}
          <Box position="absolute" top={6} right={6}>
            <WishlistButton productId={id} wishItemId={wishlistId} />
          </Box>
          {timesaleAppliedNow && <TimeSaleChip left={6} bottom={6} />}
        </ImageWrapper>

        <VStack
          justifyContent="space-between"
          height="100%"
          paddingTop={2}
          paddingLeft={6}
        >
          <VStack height="100%" maxHeight={69} width="100%" spacing={4}>
            <Box position="relative">
              <ProductTitle title={title} brand={brand} titleLineCount={1} />
            </Box>
            <HStack justifyContent="space-between" alignItems="flex-end">
              <Price marketPrice={marketPrice} sellPrice={minSellPrice} />
            </HStack>
          </VStack>

          <TextBadge direction="row" />
        </VStack>
      </HStack>
    </Clickable>
  )
}

const ImageWrapper = styled.div`
  width: ${parseSize(100)};
  height: ${parseSize(100)};
  position: relative;
  display: flex;
  flex-shrink: 0;
  border: ${parseSize(1)} solid ${colors.gray[100]};

  img {
    aspect-ratio: 1;
  }
`
