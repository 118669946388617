import { Box } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { BaseProduct } from '@src/types/product'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EmptyProductPlaceholder } from '../../common/empty-product-placeholder'
import { RankSwipeProductItem } from './rank-swipe-product-item'

type RankSwipeListProps = {
  products?: BaseProduct[]
  disabled?: boolean
}

export const RankSwipeProductList = ({
  products,
  disabled,
}: RankSwipeListProps) => {
  if (products && products.length === 0) {
    return <EmptyProductPlaceholder variant="box" />
  }

  return (
    <Box display="block">
      <Swiper
        slidesPerView={1.1}
        spaceBetween={8}
        style={{ paddingLeft: parseSize(16), paddingRight: parseSize(16) }}
        loop={(products?.length || 0) > 1}
      >
        {products?.map((product, index) => (
          <SwiperSlide key={index}>
            <RankSwipeProductItem
              product={product}
              rank={index + 1}
              disabled={disabled}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
