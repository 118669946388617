import styled from '@emotion/styled'
import { HStack, Text } from '@src/components/basic'
import { Icon } from '@src/components/common'
import Link from 'next/link'

type Props = {
  href: string
  label?: string
}

export const ViewAllButton = ({ href, label = '전체보기' }: Props) => {
  return (
    <Link href={href || ''}>
      <HStack spacing={2}>
        <Text fontSize={12} fontColor="gray.900" lineHeight={1}>
          {label}
        </Text>
        <Icon iconName="next" size={12} color="gray.600" />
      </HStack>
    </Link>
  )
}
