import { Box, Text, VStack } from '@src/components/basic'
import { CustomImage } from '@src/components/common'
import { ImageMenuComponent as ImageMenuComponentType } from '@src/types/channel-component'
import { getCustomPageRoute } from '@src/utils/get-custom-page-route'
import Link from 'next/link'
import { ComponentTitle } from './_common/component-title'

type ImageMenuComponentProps = {
  component: ImageMenuComponentType
}

export const ImageMenuComponent = ({ component }: ImageMenuComponentProps) => {
  const { items, imageRatio, title } = component
  const [width, height] = imageRatio

  return (
    <VStack paddingY={24} spacing={16}>
      <ComponentTitle title={title} />
      <Box
        paddingX={16}
        display="grid"
        rowGap={8}
        columnGap={8}
        gridTemplateColumns="1fr 1fr"
      >
        {items.map((item, index) => (
          <Link key={index} href={getCustomPageRoute(item.pagePath)}>
            <Box key={index} position="relative">
              <CustomImage
                src={item.image}
                layout="responsive"
                width={width}
                height={height}
              />
              <Box
                position="absolute"
                backgroundColor="rgba(0, 0, 0, 0.25)"
                inset="0"
                paddingX={8}
                centered
              >
                <Text
                  fontColor="white"
                  fontSize={20}
                  fontWeight="bold"
                  textAlign="center"
                >
                  {item.imageOverlayText}
                </Text>
              </Box>
            </Box>
          </Link>
        ))}
      </Box>
    </VStack>
  )
}
