import { HDivider } from '@src/components/common'
import {
  ChannelComponentResponseDto,
  ComponentTypeEnum,
} from '@src/types/channel-component'
import { BannerComponent } from './banner'
import { IconMenuComponent } from './icon-menu'
import { ImageListComponent } from './image-list'
import { ImageMenuComponent } from './image-menu'
import { MagazineComponent } from './magazine'
import { ProductListComponent } from './product-list'
import { RankListComponent } from './rank-list'
import { SubMenuComponent } from './sub-menu'
import { TimeSaleComponent } from './time-sale'

type Props = {
  component: ChannelComponentResponseDto
  hasDivider?: boolean
}

export const ChannelComponent = ({ component, hasDivider }: Props) => {
  const { componentType } = component

  return (
    <>
      {componentType === ComponentTypeEnum.Banner && (
        <BannerComponent component={component} />
      )}
      {componentType === ComponentTypeEnum.IconMenu && (
        <IconMenuComponent component={component} />
      )}
      {componentType === ComponentTypeEnum.ImageList && (
        <ImageListComponent component={component} />
      )}
      {componentType === ComponentTypeEnum.Magazine && (
        <MagazineComponent component={component} />
      )}
      {componentType === ComponentTypeEnum.ProductList && (
        <ProductListComponent component={component} />
      )}
      {componentType === ComponentTypeEnum.SubMenu && (
        <SubMenuComponent component={component} />
      )}
      {componentType === ComponentTypeEnum.TimeSale && (
        <TimeSaleComponent component={component} />
      )}
      {componentType === ComponentTypeEnum.RankList && (
        <RankListComponent component={component} />
      )}
      {componentType === ComponentTypeEnum.ImageMenu && (
        <ImageMenuComponent component={component} />
      )}
      {hasDivider && <HDivider backgroundColor="gray.100" />}
    </>
  )
}
