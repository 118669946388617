import { useComponentProductsInfiniteQuery } from '@api/hooks/product/use-component-products'
import { VStack } from '@src/components/basic'
import { LoadingSpinner } from '@src/components/common'
import {
  DisplayTypeEnum,
  ProductListComponent as ProductListComponentType,
} from '@src/types/channel-component'
import { FixedVerticalProductList } from '../../product-list/fixed-vertical'
import { SwipeProductList } from '../../product-list/swipe'
import { ComponentTitle } from './_common/component-title'

type Props = {
  component: ProductListComponentType
}

export const ProductListComponent = ({ component }: Props) => {
  const { title, items, displayType, productCols } = component
  const componentItem = items[0]
  const componentProductsInfiniteQuery = useComponentProductsInfiniteQuery({
    productIds: componentItem.mainDisplayProductList,
  })

  return (
    <VStack paddingY={24} spacing={16}>
      <ComponentTitle title={title} pagePath={componentItem.pagePath} />
      {componentProductsInfiniteQuery.isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {displayType === DisplayTypeEnum.Swipe && (
            <SwipeProductList
              productList={componentProductsInfiniteQuery.data}
            />
          )}
          {displayType === DisplayTypeEnum.Fixed && (
            <FixedVerticalProductList
              productList={componentProductsInfiniteQuery.data}
              cols={productCols || 2}
            />
          )}
        </>
      )}
    </VStack>
  )
}
