import { Box, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import {
  SectionSubTitle,
  SectionTitle,
} from '@src/components/common/typography'
import {
  DisplayTypeEnum,
  ImageRatio,
  MagazineComponent as MagazineComponentType,
} from '@src/types/channel-component'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ComponentImage } from '../../common/component-image'

type Props = {
  component: MagazineComponentType
}

export const MagazineComponent = ({ component }: Props) => {
  const { items, imageRatio, displayType } = component

  return (
    <Box paddingY={24} display="block">
      {displayType === DisplayTypeEnum.List && (
        <VStack spacing={24} paddingX={16}>
          {items.map((item, index) => {
            return (
              <MagazineItem key={index} data={item} imageRatio={imageRatio} />
            )
          })}
        </VStack>
      )}
      {displayType === DisplayTypeEnum.Swipe && (
        <Swiper
          slidesPerView={1.1}
          spaceBetween={8}
          style={{ paddingLeft: parseSize(16), paddingRight: parseSize(16) }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <MagazineItem key={index} data={item} imageRatio={imageRatio} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  )
}

type MagazineItemProps = {
  data: Props['component']['items'][0]
  imageRatio: ImageRatio
}

const MagazineItem = ({ data, imageRatio }: MagazineItemProps) => {
  return (
    <VStack spacing={12} paddingTop={16} paddingBottom={12}>
      <ComponentImage
        src={data.image}
        imageRatio={imageRatio}
        pagePath={data.pagePath}
      />
      <VStack spacing={8} paddingBottom={8}>
        <SectionTitle lineClamp={1} textAlign="center">
          {data.title}
        </SectionTitle>
        <SectionSubTitle lineClamp={2} textAlign="center">
          {data.subTitle}
        </SectionSubTitle>
      </VStack>
    </VStack>
  )
}
