import React from 'react'
import { Text, VStack } from '@src/components/basic'

export const Copyright = () => {
  return (
    <VStack spacing={16} alignItems={'center'} width={'100%'}>
      <Text color={'gray.700'} fontSize={10}>
        Copyright © 가비파트너스 주식회사. All right reserved
      </Text>
    </VStack>
  )
}
