import { Box, VStack } from '@src/components/basic'
import { CustomImage } from '@src/components/common'
import { Clickable } from '@src/components/common/clickable'
import { CountdownDisplay } from '@src/components/custom-page/countdown-product/countdown-display'
import { useProductDetailDrawer } from '@src/contexts/product-detail-drawer-context'
import { TimeRemaining } from '@src/hook/use-countdown'
import { BaseProduct, InventoryStatusEnum } from '@src/types/product'
import { DisableOverlay } from '../../common/disabled-overlay'
import { Price } from '../../common/price'
import { ProductTitle } from '../../common/product-title'
import { SoldOutOverlay } from '../../common/sold-out-overlay'
import { TextBadge } from '../../common/text-badge'
import { TimeSaleChip } from '../../common/time-sale-chip'
import { WishlistButton } from '../../common/wishlist-button'

type Props = {
  product: BaseProduct
  startDate: Date
  time: TimeRemaining
  disabled?: boolean
}

export const TimeSaleProductItem = ({
  product,
  startDate,
  time,
  disabled,
}: Props) => {
  const { openProductDetail } = useProductDetailDrawer()
  const {
    id,
    images,
    brand,
    title,
    wishlistId,
    minSellPrice,
    marketPrice,
    timesaleAppliedNow,
    inventoryStatus,
  } = product

  return (
    <Clickable
      minWidth="90%"
      onClick={() => !disabled && openProductDetail(product.id)}
      disabled={disabled}
    >
      <Box width="100%" position="relative" border={[1, 'solid', 'gray.100']}>
        <CustomImage
          src={images[0]}
          alt={title}
          layout="responsive"
          width={1}
          height={1}
        />
        <Box position="absolute" top={8} right={8}>
          <WishlistButton productId={id} wishItemId={wishlistId} />
        </Box>
        {timesaleAppliedNow && <TimeSaleChip top={8} left={8} />}
        {inventoryStatus === InventoryStatusEnum.SoldOut && <SoldOutOverlay />}
        {disabled && <DisableOverlay />}
      </Box>
      <CountdownDisplay time={time} startDate={startDate} />
      <VStack paddingX={6} paddingY={8} minHeight={82} spacing={6}>
        <ProductTitle title={title} brand={brand} titleLineCount={1} />
        <Price sellPrice={minSellPrice} marketPrice={marketPrice} />
        <TextBadge direction="column" />
      </VStack>
    </Clickable>
  )
}
