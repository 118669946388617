export type ComponentCommon = {
  componentName: string
  temporarySettingCode?: string
  displayType?: DisplayTypeEnum

  isFrame: boolean
  isRemovable: boolean
  isEditable: boolean
  isActive: boolean

  itemMinCount?: number
  itemMaxCount?: number
}

export type ComponentItemCommon = {
  id: string
  newId?: string
  originalId?: string

  isForPage: boolean
  pagePath: string
}

export enum ComponentTypeEnum {
  Header = 'header',
  TopMenu = 'top_menu',
  BottomMenu = 'bottom_menu',
  Footer = 'footer',
  Banner = 'banner',
  ProductList = 'product_list',
  RankList = 'rank_list',
  ImageList = 'image_list',
  ImageMenu = 'image_menu',
  SubMenu = 'sub_menu',
  IconMenu = 'icon_menu',
  TimeSale = 'time_sale',
  Point = 'point',
  Magazine = 'magazine',
}

export enum DisplayTypeEnum {
  Fixed = 'fix',
  Rolling = 'rol',
  Button = 'button',
  Scroll = 'scroll',
  Swipe = 'swipe',
  Accordion = 'accordion',
  List = 'list',
}

export enum ItemTypeEnum {
  Category = 'category',
  Self = 'self',
}

export type ImageRatio = [width: number, height: number]
export type ImageLayout = [row: number, column: number]
