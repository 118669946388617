import { Box, HStack, VStack } from '@src/components/basic'
import { SectionTitle } from '@src/components/common'
import { SectionSubTitle } from '@src/components/common/typography'
import { getCustomPageRoute } from '@src/utils/get-custom-page-route'
import { ViewAllButton } from '../../../common/view-all-button'

type ComponentTitleProps = {
  title?: string
  subTitle?: string
  pagePath?: string
}

export const ComponentTitle = ({
  title,
  subTitle,
  pagePath,
}: ComponentTitleProps) => {
  return (
    <HStack height={48} paddingX={16} justifyContent="space-between">
      <VStack spacing={6}>
        <SectionTitle>{title || ''}</SectionTitle>
        {subTitle && <SectionSubTitle>{subTitle}</SectionSubTitle>}
      </VStack>
      {pagePath && <ViewAllButton href={getCustomPageRoute(pagePath)} />}
    </HStack>
  )
}
