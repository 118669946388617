import { useComponentProductsInfiniteQuery } from '@api/hooks/product/use-component-products'
import { VStack } from '@src/components/basic'
import { LoadingSpinner } from '@src/components/common'
import { TimeSaleComponent as TimeSaleComponentType } from '@src/types/channel-component'
import { TimeSaleProductList } from '../../product-list/time-sale'
import { ComponentTitle } from './_common/component-title'

type Props = {
  component: TimeSaleComponentType
}

export const TimeSaleComponent = ({ component }: Props) => {
  const { items, title } = component
  const firstItem = items?.[0]
  const componentProductsInfiniteQuery = useComponentProductsInfiniteQuery({
    productIds: firstItem.mainDisplayProductList,
  })

  return (
    <VStack spacing={16} paddingY={24}>
      <ComponentTitle title={title} pagePath={firstItem?.pagePath} />
      {!componentProductsInfiniteQuery.data && <LoadingSpinner />}
      {componentProductsInfiniteQuery.data && (
        <TimeSaleProductList
          productList={componentProductsInfiniteQuery.data}
          startDt={component.startDt}
          endDt={component.endDt}
        />
      )}
    </VStack>
  )
}
